import React from "react";
import "../styles/card.scss";

const Card = (props: any) => {
  return (
    <a href={props.href}>
      <div className="view view-fifth">
        {props.icon ? (
          <img
            src={props.icon}
            alt=""
            className="blck"
            width={80}
            height={80}
          />
        ) : (
          <></>
        )}
        <h3>{props.heading}</h3>

        <div className="mask">
          <div
            style={{
              background: "rgba(255, 255, 255, 0.9)",
              paddingBottom: "0.5rem",
            }}
          >
            <h4>{props.heading}</h4>
            <p>{props.info}</p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Card;
