import * as React from "react";
import Layout from "../components/Layout";
import PartnerCarousel from "../components/carousels/PartnerCarousel";
import valuesFlipBox from "../components/flipboxes/valuesFlipBox";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "gatsby";

//Images
import rsaFlag from "../images/rsa-flag.svg";
import productivity from "../images/arrows.svg";
import sustainable from "../images/plant.svg";
import ethics from "../images/clock.svg";
import relationships from "../images/shake.svg";
import bbbee from "../images/bbbee.png";
// import sustainImg from '../images/sustain.png';

import liezl from "../images/liezl.png";
import steved from "../images/steved.png";
import jonathan from "../images/jonathan.png";
import willie from "../images/willie.png";
import maziar from "../images/mazi.jpg";

import cloudXchange from "../images/partners/cloud-xchange.png";
import business from "../images/group/business.png";
import application from "../images/group/application.png";
import data from "../images/group/data.png";
import technology from "../images/group/technology.png";

import squareElementHead from "../images/redblock2.svg";
import squareElement1 from "../images/square-element1.svg";

//CSS
import "../styles/index.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import "../styles/animations.scss";
import SEO from "../components/seo";
// import { img } from 'gatsby-plugin-image';

// markup
const AboutPage = () => {
  const props = {
    seoTitle: "About",
    seoDescription:
      "Strategix Technology Solutions is a division of the Strategix Group. We enable enterprise digital transformation by unlocking value in invested software.",
  };

  return (
    <Layout>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* About Hero */}
        <section>
          <div className="hero" id="about">
            <div className="flex-col-c">
              <ScrollAnimation animateIn="animate__fadeIn" duration={1}>
                <h1 className="fade-intro">About Us</h1>
                <p className="fade-intro">
                  Solving business problems since 2005.{" "}
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        <div className="square-element-head">
          <img alt="" src={squareElementHead} />
        </div>

        {/* Strategix Group */}
        <section className="half" style={{ zIndex: "9" }} id="strategix-group">
          <div className="sect-wrap">
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp">
                <h1
                  className="fade-in-up"
                  style={{ zIndex: "9", textAlign: "center" }}
                >
                  The Strategix Group
                </h1>
                <p
                  className="fade-in-up"
                  style={{ zIndex: "9", textAlign: "center" }}
                >
                  Unlock value in invested software and enable digital
                  transformation.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <div className="sts-group-img">
                  <img alt="" src={business} className="image-fit" />
                  <img alt="" src={application} className="image-fit" />
                  <div className="image-wrapper shine">
                    <img alt="" src={technology} className="image-fit" />
                  </div>
                  <img alt="" src={data} className="image-fit" />
                </div>
                <div className="flex-col-c">
                  <div className="grid-column-13">
                    <div className="story-description">
                      <div
                        style={{
                          height: "100%",
                          // backgroundColor: 'red',
                          // justifyContent: 'center',
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p>
                          <a
                            href="https://strategix.co.za"
                            style={{
                              textDecoration: "none",
                              color: "var(--blue)",
                              fontWeight: "bold",
                            }}
                            target="_blank"
                          >
                            The Strategix Group
                          </a>{" "}
                          has been solving business problems for almost two
                          decades. Having implemented business management
                          solutions in 60 countries across 6 continents, we
                          impart extensive industry knowledge and best practice.
                          <br />
                          <br />
                          Strategix holds an impeccable global project track
                          record in the SME and Enterprise space. As a 51% black
                          and female-owned level 2 BEE organisation, we
                          translate business requirements into tangible
                          benefits.
                          <br />
                          <br />
                          Our approach is simple, methodical and agile when
                          needed as we progress your operational optimisation
                          program and digital transformation journey.
                        </p>
                        <div style={{ display: "flex", maxWidth: "90%" }}>
                          <img
                            alt=""
                            src={cloudXchange}
                            className="image-fit"
                            id="thirty-five"
                          />
                          <img
                            alt=""
                            src={bbbee}
                            className="image-fit"
                            id="twenty-five"
                          />
                          <img
                            alt=""
                            src={rsaFlag}
                            className="image-fit"
                            id="thirty"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="sts-story">
                      <div className="story-wrapper">
                        <div>
                          <h2>Strategix Technology Solutions</h2>
                          <p>
                            Founded in 2005, Strategix Technology Solutions
                            (STS) is a division of the Strategix Group,
                            providing best in class cloud computing solutions.
                            As a premiere information technology services
                            provider, STS enables digital transformation by
                            unlocking enterprise value in invested software.
                          </p>
                          <p style={{ fontWeight: "bold" }}>
                            Strategix Technology Solutions implements successful
                            projects globally and serves hundreds of clients in
                            different industries.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </section>

        {/* Our Values */}
        <section id="values">
          <div className="values-wrapper">
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
                <h1 style={{ marginTop: "0" }} className="fade-in-up">
                  Our Organisational Values
                </h1>
                <p style={{ textAlign: "center" }}>
                  Technology experts that care.
                </p>
              </ScrollAnimation>
              <br />
              <br />
              {valuesFlipBox()}
              <br />
              <br />
              <h2>Large Enough To Deliver, Small Enough To Care</h2>
            </div>
          </div>
        </section>
        <div
          className="square-element1"
          style={{ transform: "rotate(180deg)" }}
        >
          <img
            alt=""
            src={squareElement1}
            style={{ transform: "scale(0.5)" }}
          />
        </div>

        {/* Culture */}
        <section id="ethos">
          <div className="sect-wrap" id="our-culture">
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h1
                  style={{ marginTop: "0", textAlign: "center" }}
                  className="fade-in-up"
                >
                  Our Ethos
                </h1>
                <p className="fade-in-up">
                  Our people are our most invaluable assets.
                </p>
              </ScrollAnimation>
              <div className="grid-column-8">
                <div className="span-2" style={{ paddingLeft: "1rem" }}>
                  <ScrollAnimation
                    animateIn="animate__fadeInLeftBig"
                    duration={0.75}
                    delay={50}
                  >
                    <img
                      alt=""
                      src={relationships}
                      className="image-fit culture-title"
                    />
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                  >
                    <h3 style={{ color: "var(--red)" }}>
                      RELATIONSHIPS
                      <br /> FIRST
                    </h3>

                    <p>
                      We practice the art of humility and put relationships
                      first. At every step, we work to save you money whilst
                      enhancing and improving your operations.
                    </p>
                  </ScrollAnimation>
                </div>
                <div className="span-2" style={{ paddingLeft: "1rem" }}>
                  <ScrollAnimation
                    animateIn="animate__fadeInLeftBig"
                    duration={0.75}
                    delay={100}
                  >
                    <img
                      alt=""
                      src={sustainable}
                      className="image-fit culture-title"
                    />
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                  >
                    <h3 style={{ color: "var(--red)" }}>
                      SUSTAINABLE <br /> BUSINESS
                    </h3>
                    <p>
                      We are a business platform and tools provider, connecting
                      the dots and bringing together an ecosystem of
                      applications, enabling organisations of all sizes to build
                      sustainable digital capability.
                    </p>
                  </ScrollAnimation>
                </div>
                <div className="span-2" style={{ paddingLeft: "2.5rem" }}>
                  <ScrollAnimation
                    animateIn="animate__fadeInLeftBig"
                    duration={0.75}
                    delay={150}
                  >
                    <img
                      alt=""
                      src={ethics}
                      className="image-fit culture-title"
                    />
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                  >
                    <h3 style={{ color: "var(--red)" }}>
                      ETHICS AND <br />
                      HONESTY
                    </h3>
                    <p>
                      We are experienced and creative, but our ethics and
                      honesty truly differentiate us. We proudly invest time
                      into understanding your operations.
                    </p>
                  </ScrollAnimation>
                </div>
                <div className="span-2" style={{ paddingLeft: "2.5rem" }}>
                  <ScrollAnimation
                    animateIn="animate__fadeInLeftBig"
                    duration={0.75}
                    delay={201}
                  >
                    <img
                      alt=""
                      src={productivity}
                      className="image-fit culture-title"
                    />
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                  >
                    <h3 style={{ color: "var(--red)" }}>
                      PRODUCTIVITY
                      <br />
                      <br />
                    </h3>
                    <p>
                      We are in the business of enabling productivity from
                      anywhere, ensuring rapid adaption of improved business
                      processes and enhancing customer experiences.
                    </p>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Sustainability */}
        <a id="sustainability"></a>
        <section
          className="half flex-col-c pad-inline man-sec-alt"
          id="sustain"
        >
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1
                style={{
                  textAlign: "center",
                  paddingBottom: "0.5rem",
                  // paddingTop: '2.5rem',
                }}
                className="fade-in-up"
              >
                Sustainability
              </h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <div className="service-feature-list">
                <p>
                  We are committed to creating a sustainable digital ecosystem.
                </p>
              </div>
            </ScrollAnimation>
            <div className="d-flex" style={{ width: "100%" }}>
              <div className="half-width"></div>
              <div className="half-width">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                  <p>
                    As a business platform and tools provider, we recognise the
                    need for ethical and sustainable participation in the global
                    community. Our actions have far-reaching consequences to not
                    only the organisations we directly engage with, but also to
                    local communities and our surrounding environment.
                  </p>
                  <p>
                    Underpinned by our Sustainable Business ethos, we bring
                    together an ecosystem of partners and suppliers with a
                    demonstrated commitment to operating sustainably – socially,
                    economically and environmentally.
                  </p>
                  <p>
                    Through our partnership with Teraco, our exclusive data
                    centre provider, we are committed to{" "}
                    <a
                      style={{ color: "var(--blue)" }}
                      target="_blank"
                      href="https://www.teraco.co.za/about/sustainability/#progress"
                    >
                      100% sustainable cloud operations by 2035.
                    </a>
                  </p>
                </ScrollAnimation>
                <div
                  style={{
                    paddingBlock: "2rem",
                  }}
                >
                  <Link to="/contact">
                    <button>
                      get in touch <span>&#x279C;</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="sect-wrap team-wrap" id="sustain">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
            <h1 style={{ marginTop: '0' }} className="fade-in-up">
              Sustainability
            </h1>
            <p className="fade-in-up" style={{ textAlign: 'center' }}>
              We are committed to creating a sustainable digital ecosystem.
            </p>
          </ScrollAnimation>
          <div className="sustainability-text">
            <div>
            </div>
            <div>
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <p>
                  As a business platform and tools provider, we recognise the
                  need for ethical and sustainable participation in the global
                  community. Our actions have far-reaching consequences to not
                  only the organisations we directly engage with, but also to
                  local communities and our surrounding environment.
                </p>
                <p>
                  Underpinned by our Sustainable Business ethos, we bring
                  together an ecosystem of partners and suppliers with a
                  demonstrated commitment to operating sustainably – socially,
                  economically and environmentally.
                </p>
                <p>
                  Through our partnership with Teraco, our exclusive data centre
                  provider, we are committed to 100% sustainable cloud
                  operations by 2035.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </section> */}

        <section className="sect-wrap team-wrap" id="team">
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1 style={{ marginTop: "0" }} className="fade-in-up">
                Our Leadership Team
              </h1>
              <p className="fade-in-up" style={{ textAlign: "center" }}>
                Meet Our Leadership Team.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <div className="team">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.5}>
                <div className="team-member fade-intro">
                  <div id="team-image">
                    <img alt="" src={steved} className="image-fit" />
                  </div>
                  <div id="team-bio">
                    <h3>Steve Dalgarno</h3>
                    <h4>Managing Director</h4>
                    <h6>
                      Steve Dalgarno is a passionate Digital and Cloud
                      Transformation specialist with a proven track record of
                      technology leadership. His leadership philosophy is rooted
                      in one central idea: people are the core component to the
                      success of any business.
                      {/* <br />
                    <br /> */}
                      Steve leads Strategix with this idea in mind, developing
                      teams that are not only technically capable but form
                      close-knit relationships and work together to inspire,
                      stretch outside of the box, challenge conventional
                      thinking and walk with clients step-by-step along their
                      Digital Transformation Journey.
                      <br />
                      <br />
                      He identifies the accelerated digitalisation and
                      modernisation of traditional business processes and
                      solutions as crucial changes that shape the way modern
                      business is conducted. While many organisations have
                      neglected skills development and mentorship during this
                      time, Steve ensures Strategix Technology Solutions invests
                      time, skills and energy into its people making it the
                      business partner of choice in the industry.
                    </h6>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.5}>
                <div className="team-member fade-intro">
                  <div id="team-image">
                    <img alt="" src={jonathan} className="image-fit" />
                  </div>
                  <div id="team-bio">
                    <h3>Jonathan Goldberg</h3>
                    <h4>Managed Services Director</h4>
                    <h6>
                      Jonathan Goldberg brings with him 10+ years of technical
                      and leadership experience to his role as Managed Services
                      Director. Detailed and focused, his knowledge extends far
                      beyond the hardware platform and into the software stack,
                      shaping the managed services journey to deliver end-to-end
                      solutions to customers across multi-cloud, hybrid, and
                      virtualised environments.
                      <br />
                      <br />
                      Jonathan’s expertise lies in implementing relevant digital
                      solutions that align to customer business goals, coupling
                      the latest technology solutions with highly skilled
                      individuals and teams to achieve the full potential of
                      managed services. He brings alignment between Strategix’s
                      Managed Services offering and the shifting requirements
                      businesses demand from their technology providers –
                      building a portfolio of bucketed solutions from backup to
                      security to disaster recovery and more.
                    </h6>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.5}>
                <div className="team-member fade-intro">
                  <div id="team-image">
                    <img alt="" src={willie} className="image-fit" />
                  </div>
                  <div id="team-bio">
                    <h3>Willie Jansen van Rensburg</h3>
                    <h4>Cloud Technologies Group Executive</h4>
                    <h6>
                      Willie Jansen Van Rensburg is an accomplished Technology
                      Executive, with a professional track record 30+ years in
                      the making. In his early career he earned his technical
                      stripes taking on various engineering and implementation
                      functions. The latter half of his working life has seen
                      Willie take on more executive leadership roles most
                      notably as Managing Director for Citrix Consulting and as
                      executive at Axiz Cloud Technologies heading up their
                      VMware portfolio.
                      <br />
                      <br />
                      Meticulous in his approach, Willie’s entrepreneurial
                      spirit translates into a keen focus on building and
                      nurturing thriving customer relationships. In his current
                      role as Cloud Technologies Group Executive, he is in
                      charge of the design, execution and acceleration of all
                      cloud strategies for Strategix Technology Solutions.
                    </h6>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.5}>
                <div className="team-member fade-intro">
                  <div id="team-image">
                    <img alt="" src={liezl} className="image-fit" />
                  </div>
                  <div id="team-bio">
                    <h3>Liezl van Staden</h3>
                    <h4>Solutions Sales Manager</h4>
                    <h6>
                      Liezl Van Staden has worked in the IT industry for over
                      two decades, accumulating multinational experience in a
                      host of sales and leadership roles. During this time she
                      has cultivated a sharp business acumen centred around
                      customer relationships and solution development. Liezl is
                      passionate about digital transformation and
                      conceptualising technology solutions for modern
                      enterprises.
                      <br />
                      <br />
                      Her approach places a strong emphasis on long-term
                      success, melding a clear understanding of application
                      modernisation and integration with a deep background in
                      VMware, Microsoft and cloud concepts. This approach allows
                      Liezl to match customers with the right technology partner
                      for their requirements. Liezl is skilled in new business
                      development, channel and key account management and
                      leading sales teams.
                      <i>
                        “Be comfortable in an ever-changing world faced with
                        uncomfortable unknowns.”
                      </i>
                    </h6>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.5}>
                <div className="team-member fade-intro">
                  <div id="team-image">
                    <img alt="" src={maziar} className="image-fit" />
                  </div>
                  <div id="team-bio">
                    <h3>Maziar Mahmoudi</h3>
                    <h4>Professional Services Manager</h4>
                    <h6>
                      A Computer Scientist by background, Maziar Mahmoudi is a
                      uniquely skilled information technology practitioner with
                      a knack for finding simple solutions to complex problems.
                      His professional career, spanning over 10 years and 2
                      continents, has seen him implement technology solutions in
                      a wide range of industries. From leading system and
                      network administration teams at non-profits to deploying
                      and managing the environment of a leading bank’s
                      infrastructure, Maziar has done it all.
                      <br />
                      <br />
                      The consummate technician, he currently heads up Strategix
                      Technology Solutions’ professional services portfolio
                      while staying on the edge of new technologies at every
                      turn. In this role he is tasked with guiding customers
                      through the professional services roadmap, from initial
                      consultancy and assessment through to successful project
                      implementation.
                    </h6>
                  </div>
                </div>{" "}
              </ScrollAnimation>
            </div>
          </div>
        </section>

        <div className="contact-svg" />
        <PartnerCarousel />
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "5rem",
          }}
        >
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
            <h1>Have A Question?</h1>
            <p style={{ textAlign: "center" }}>We're only a click away.</p>
          </ScrollAnimation>

          <Link to="/contact">
            <button>
              Contact Us <span>&#x279C;</span>
            </button>
          </Link>
        </section>

        {/* Contact */}
        {/* <section className="half">
          <ContactForm />
        </section> */}
      </main>
    </Layout>
  );
};

export default AboutPage;
