import React from "react";

import "../../styles/card.scss";
//Icons
import { IoIosArrowDown } from "react-icons/io";
import { BiSearchAlt } from "react-icons/bi";
import { FaRegCircle } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import ScrollAnimation from "react-animate-on-scroll";

import Card from "../Card";

export default function valuesFlipBox() {
  return (
    <div className="outer-container">
      <div className="row">
        {/******** Desktop Cards *******/}

        <div className="desktop-card">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1} delay={0}>
            <div className="inner-container-3">
              <Card
                heading="Agility and Flexibility"
                visibility="true"
                info="Agility and flexibility enable us to react to market changes and client demands."
              />
            </div>{" "}
          </ScrollAnimation>
        </div>

        <div className="desktop-card">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={100}
          >
            <div className="inner-container-3">
              <Card
                heading="Beyond Implementations"
                automation="true"
                info="To go beyond implementations as true value only transpires post project."
              />
            </div>
          </ScrollAnimation>
        </div>

        <div className="desktop-card">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={200}
          >
            <div className="inner-container-3">
              <Card
                heading="Architectural, Deployment and Assessment"
                application="true"
                info="Provide architectural, deployment and assessment services tailored to underpin customers' business goals."
              />
            </div>{" "}
          </ScrollAnimation>
        </div>

        <div className="desktop-card">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            duration={1}
            delay={300}
          >
            <div className="inner-container-3">
              <Card
                heading="Digital Enterprise Journey"
                services="true"
                info="Engagement with clients in guiding customers through their Digital Enterprise Journey."
              />
            </div>
          </ScrollAnimation>
        </div>

        {/* Mobile Here */}
      </div>
    </div>
  );
}
